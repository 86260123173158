import {Component, EventEmitter, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Region } from '../../../shared/interfaces/region';
import { forkJoin, Subscription } from 'rxjs';
import { CustomerType } from '../../../shared/interfaces/customer-type';
import { AdminCustomer } from '../../../shared/interfaces/admin/admin-customer';
import { CustomerService } from '../../../shared/services/customer.service';
const { v1: uuidv1 } = require('uuid');
const { v1: uuidv4 } = require('uuid');

@Component({
    selector: 'app-customer-form',
    templateUrl: './customer-form.component.html',
    styleUrls: ['./customer-form.component.scss']
  })
  export class CustomerFormComponent implements OnDestroy {
    customerSubscription: Subscription;
    constructor(
      public dialogRef: MatDialogRef<CustomerFormComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _customerService: CustomerService
      ) {
        this.getRegionsAndTypes();
    }
    currentDate = new Date().getTime();
    customerPost: AdminCustomer = {
      customerTypeId: '',
        regionId: '',
        languageTag: 'en-US',
        contact_Name: '',
        contact_Telephone: '',
        contact_Email: '',
        contact_Cellphone: '',
        contact_Address: '',
        fieldSupportContact_Name: '',
        salesContact_Name: '',
        code: this.removeHyphens(uuidv1()),
        id: uuidv4(),
        name: '',
        description: '',
        content: null
    };
    public event: EventEmitter<any> = new EventEmitter();
    // fill these arrays through actual service calls
    regions = [];
    languages = [];
    types = [];
    submitted = false;
    customerLanguage = [
      {key: 'English', value: 'en-US'},
      {key: 'Nepalese', value: 'ne'},
      {key: 'Russian', value: 'rus'}
    ];

    getRegionsAndTypes() {
      const getRegions = this._customerService.getRegions();
      const getTypes = this._customerService.getCustomerTypes();

      forkJoin([getRegions, getTypes])
      .subscribe(results => {
        const regions = results[0];
        const types = results[1];

        this.regions = regions;
        this.types = types;
        this.populateRegions(regions);
        this.populateCustomerTypes(types);
        if (this.types !== undefined) {
          this.types = this.types.sort((a, b) => {
            return (a.code < b.code) ? -1 : (a.code > b.code) ? 1 : 0;
          });
        }
      })
    }

    ngOnDestroy() {
      // if (this.customerSubscription) {
      //   this.customerSubscription.unsubscribe();
      // }
    }

    populateRegions(regions: Region[]): object[] {
      this.regions = [];
      for (let i = 0; i < regions.length; i++) {
        const r = regions[i];
        const region = {key: r.basicName , value: r.id };
        this.regions.push(region);
      }
      return this.regions;
    }

    populateCustomerTypes(types: CustomerType[]): object[] {
      this.types = [];
      for (let i = 0; i < types.length; i++) {
        const t = types[i];
        const type = {key: t.basicName , value: t.id };
        this.types.push(type);
      }
      return this.types;
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

    defaultCustomer(): AdminCustomer {
      this.customerPost = {
        customerTypeId: this.types[1].value,
        regionId: this.regions[this.regions.length - 1].value,
        languageTag: 'en-US',
        contact_Name: 'New Customer',
        contact_Telephone: '',
        contact_Email: null,
        contact_Cellphone: null,
        contact_Address: '',
        fieldSupportContact_Name: '',
        salesContact_Name: '',
        code: this.removeHyphens(uuidv1()),
        id: null,
        name: 'Default Customer',
        description: 'New defaulted customer description',
        content: null
      };
      return this.customerPost;
    }

    onSubmit(): void {
      this.submitted = true;
      this.customerSubscription = this._customerService.createAdminCustomer(this.customerPost, true).subscribe(c => {
        console.log('customer has been created succesfully: ' + c);
        this.event.emit({data: this.customerPost});
        // const _msal = new MsalService();
        // _msal.azureGraphApi();
      });
      this.dialogRef.close();
    }

    removeHyphens(code: string): string {
      const replaced = code.replace(/-/g, '');
      return replaced;
    }
  }
