import { map, catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ConstantService } from "./constant.service";
import { Observable } from "rxjs";
import { Customer } from "../interfaces/customer";
import { Region } from "../interfaces/region";
import { CustomerType } from "../interfaces/customer-type";
import { environment } from "../../../environments/environment";
import { ExtraCustomerInfo } from "../classes/extra-customer-info";
import { AssetOwnership } from "../interfaces/asset-ownership";
import { EmailStructure } from "../classes/email-structure";
import { UserType, User } from "..";
import { SnackService } from "./extra/snack.service";
import { UserGroup } from "../interfaces/user-group";
import { LocalStorageService } from "../../core";

@Injectable()
export class CustomerService {
  URL = `${this._constService.CONST.URL}tables/Customer`;
  private functionUrl =
    "https://esendgrid.azurewebsites.net/api/InvitationEmail?code=PPUT1vs8/tWObr7Zvt3InSYEowPt5RRmVF7ayC0mhR1MDJ8weDUpaA==";
  constructor(
    private _http: HttpClient,
    private _constService: ConstantService,
    private _localStorage: LocalStorageService,
    private _snack: SnackService
  ) { }

  public createCustomer(customer: Customer) {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .post(this.URL, customer, { params: params })
      .pipe(map(this._constService.extractData));
  }

  public updateCustomer(id: string, customer: any): Observable<Customer> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      "Content-Type": "application/json",
    };
    const URL = `${this.URL}/${id}`;
    return this._http.patch(URL, customer, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleErrorObservable)
    );
  }

  getRegions(): Observable<Region[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .get<Region[]>(environment.apiUrl + "tables/Region", { params: params })
      .pipe(
        map((resp: any) => {
          this._localStorage.setItem('regions', resp);
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getCustomerTypes(): Observable<CustomerType[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .get<CustomerType[]>(environment.apiUrl + "tables/CustomerType", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          this._localStorage.setItem('customerTypes', resp);
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getCustomers(): Observable<Customer[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .get<Customer[]>(environment.apiUrl + "tables/Customer", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return this.parseCustomers(resp);
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getAdminCustomers(): Observable<ExtraCustomerInfo[]> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
    };
    return this._http
      .get<ExtraCustomerInfo[]>(environment.apiUrl + "api/admin/customer", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(this._constService.handleErrorObservable)
      );
  }

  getCustomerDetails(id: string): Observable<Customer> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<Customer>(`${environment.apiUrl}tables/Customer/${id}`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  getAssetOwnerships(): Observable<AssetOwnership[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<AssetOwnership[]>(`${environment.apiUrl}tables/AssetOwnership`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  removeOwnership(id: string, ownership: any): Observable<AssetOwnership> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/AssetOwnership/${id}`;
    return this._http.patch(URL, ownership, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => {
        console.log("Update AssetOwnership Response:", data);
        // this._router.navigate(['/customers/' + `${this.customerId}/` + 'assets']);
      })
    ); // debug
  }

  getUserTypes(): Observable<UserType[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<UserType[]>(environment.apiUrl + "tables/UserType", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          this._localStorage.setItem('userTypes', resp);
          return resp;
        }),
        tap((data) => console.log("GetUserTypes:", data))
      );
  }

  createUser(user: User) {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .post(`${environment.apiUrl}tables/User`, user, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("User created:", data)),
        catchError((err, caught) =>
          this._constService.handleErrorObservable(err)
          // this._constService.handleErrorToast(
          //   err,
          //   "Could not Create user...Please check for unique email"
          // )
        )
      );
  }

  createAdminUser(user: User) {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
      entity: user
    };
    return this._http
      .post(environment.apiUrl + `api/admin/user`, user, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("User created:", data)),
        catchError((err) =>
          this._constService.handleErrorToast(
            err,
            "Could not Create user...Please check for unique email"
          )
        )
      );
  }

  sendGridEmail(to: string, from: string, name: string) {
    const emailToSend = new EmailStructure(
      to,
      from,
      "Earthscout Signup Invite",
      name,
      ""
    );
    const params: any = {
      "Content-Type": "application/json",
    };
    return this._http
      .post(this.functionUrl, emailToSend, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap(
          (data) =>
            this._constService.handleRequestToast(data, "Invite email was sent")
          // console.log('email sent: ', data)
        )
      );
  }

  createAdminCustomer(customer: any, defaultSetup: boolean) {
    let doSetup = 0;
    if (defaultSetup === true) {
      doSetup = 1;
    }
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      doSetup: doSetup,
    };
    return this._http
      .post(environment.apiUrl + "api/admin/customer", customer, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("Customer created:", data))
      );
  }

  getTableAssetOwnerships(): Observable<AssetOwnership[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<AssetOwnership[]>(environment.apiUrl + `tables/AssetOwnership`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  public updateTableCustomer(id: string, customer: any): Observable<Customer> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/Customer/${id}`;
    return this._http.patch(URL, customer, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update Customer Response:", data))
    ); // debug
  }

  public tableDeleteCustomer(customerId: string): Observable<boolean> {
    return this._http
      .delete(`${environment.apiUrl}tables/Customer/${customerId}`, {
        params: this._constService.defaultParams,
      })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap((data) => console.log("deleted customer:", data))
      );
  }

  public deleteCustomer(customerId: string): Observable<boolean> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: customerId,
    };
    const url = environment.apiUrl + "api/admin/customer";
    return this._http.delete(url, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleError())
    );
  }

  deleteUserGroup(userGroupId: string): Observable<number> {
    const URL = `${environment.apiUrl}tables/UserGroup/${userGroupId}`;
    return this._http
      .delete(URL, { params: this._constService.defaultParams })
      .pipe(
        map((res: any) => {
          return res;
        }),
        tap((data) => console.log("UserGroup delete returned:", data))
      ); // debug
  }

  // CRUD functions
  getUserDetails(id: string): Observable<User> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<User>(environment.apiUrl + `tables/User/${id}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  getAdminUserDetails(userAuthName: string, customerId?: string): Observable<any> {
    let params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      customerIds: customerId,
      userauthname: userAuthName
    };
    if (customerId == undefined) {
      params = {
        "ZUMO-API-VERSION": "2.0.0",
        userauthname: userAuthName
      };
    }
    return this._http
      .get<User>(environment.apiUrl + `api/admin/user`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  getAdminUserDetailsByName(userAuthName: string): Observable<any> {
    let params: any = {
      username: userAuthName,
      "ZUMO-API-VERSION": "2.0.0"
    };
    return this._http
      .get<User>(environment.apiUrl + `api/admin/user/name`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      );
  }

  getAdminUserByCustomerId(customerId: string): Observable<any> {
    let params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      customerIds: customerId
    };
    return this._http
      .get<User>(environment.apiUrl + `api/admin/user`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  getAdminUserGroupMember(id: string): Observable<any[]> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      requestIds: id,
    };
    return this._http
      .get<any[]>(environment.apiUrl + `api/admin/usergroupmember`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        tap((data) => console.log("server data:", data))
      ); // debug
  }

  updateAppUser(id: string, user: any): Observable<User> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `api/admin/user`;
    return this._http.patch(URL, user, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update Admin User Response:", data))
    );
  }

  public updateUser(id: string, user: any): Observable<User> {
    if (user.userSettingsInfoContent == null) {
      user.userSettingsInfoContent = JSON.stringify({
        languageTag: "en-US",
        scoutVisibility: null,
        sensorVisibility: null,
        regions: [],
        subjects: [],
        units: [
          {
            name: "Temperature",
            value: "F"
          },
          {
            name: "SoilEC",
            value: "uS/cm"
          }
        ],
        scoutSettings: [],
        sensorSettings: []
      });
    }
    
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/User/${id}`;
    return this._http.patch(URL, user, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update User Response:", data))
    ); // debug
  }

  public deleteUser(userId: string): Observable<number> {
    const params: any = {
      "ZUMO-API-VERSION": this._constService.CONST.ZUMOAPIVersion,
      id: userId,
    };
    const url = environment.apiUrl + "api/admin/user";
    return this._http.delete(url, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this._constService.handleError())
    );
  }

  // CRUD functions
  getUserGroupDetails(id: string): Observable<UserGroup> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
    };
    return this._http
      .get<UserGroup>(environment.apiUrl + `tables/UserGroup/${id}`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
          // return this.parseCustomers(resp);
        }),
        tap((data) => console.log("UserGroup data:", data))
      ); // debug
  }

  updateUserGroup(id: string, userGroup: any): Observable<UserGroup> {
    const params: any = {
      "ZUMO-API-VERSION": "2.0.0",
      "Content-Type": "application/json",
    };
    const URL = environment.apiUrl + `tables/UserGroup/${id}`;
    return this._http.patch(URL, userGroup, { params: params }).pipe(
      map((res: any) => {
        return res;
      }),
      tap((data) => console.log("Update UserGroup Response:", data))
    ); // debug
  }

  // Parsers ------------------------->
  parseCustomers(data): Customer[] {
    const customers: Customer[] = [];
    for (let i = 0; i < data.length; i++) {
      const returnedCustomer = data[i];
      const customer: Customer = <Customer>{
        customerTypeId: returnedCustomer.customerTypeId,
        regionId: returnedCustomer.regionId,
        languageTag: returnedCustomer.languageTag,
        contact_Name: returnedCustomer.contact_Name,
        contact_Telephone: returnedCustomer.contact_Telephone,
        contact_Cellphone: returnedCustomer.contact_Cellphone,
        contact_Email: returnedCustomer.contact_Email,
        fieldSupportContact_Name: returnedCustomer.fieldSupportContact_Name,
        salesContact_Name: returnedCustomer.salesContact_Name,
        username: returnedCustomer.username,
        code: returnedCustomer.code,
        basicName: returnedCustomer.basicName,
        basicDescription: returnedCustomer.basicDescription,
        isAvailable: returnedCustomer.isAvailable,
        id: returnedCustomer.id,
        version: returnedCustomer.version,
        createdAt: returnedCustomer.createdAt,
        updatedAt: returnedCustomer.updatedAt,
        deleted: returnedCustomer.deleted,
        content: returnedCustomer.content,
      };
      customers.push(customer);
    }
    return customers;
  }
}
